import { ChevronDownIcon } from '@heroicons/react/20/solid';

export const DropdownButtonPrimitive = ({
  prefix,
  disabled,
  showArrow,
  children,
}: {
  prefix?: string;
  showArrow: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <>
      <div className="inline-flex z-40 items-center gap-1 rounded-l-md text-carbon-700 overflow-hidden">
        {prefix && (
          <p className="text-sm font-medium whitespace-nowrap">{prefix}</p>
        )}
        {children}
      </div>
      {!disabled && showArrow && (
        <ChevronDownIcon
          className="h-5 w-5 text-[#192026]"
          aria-hidden="true"
        />
      )}
    </>
  );
};
