export type DropdownOptionGroupPrimitiveProps = {
  label: string;
  children: React.ReactNode;
};

export const DropdownOptionGroupPrimitive = ({
  label,
  children,
}: DropdownOptionGroupPrimitiveProps) => {
  // Don't show empty groups, for instance when using autocomplete and no options matched
  if (
    // If no children, or children is null
    !children ||
    // Or children is an array and it's empty (length is 0)
    (Array.isArray(children) && children.length === 0)
  ) {
    return null;
  }

  return (
    <>
      <div className="text-xs text-carbon-500 font-medium px-2 py-1 mt-1 bg-white border-b-carbon-100 border-b-2 sticky top-0">
        {label}
      </div>
      {children}
    </>
  );
};
