const ONE_OPTION_HEIGHT = 36;
// Show cropped 7th option to indicate there are more below
const MAX_LIST_HEIGHT = Math.round(ONE_OPTION_HEIGHT * 6.6);

export const DropdownOptionsScrollerPrimitive = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div
      className="overflow-y-auto relative [&>:last-child]:relative [&>:last-child]:z-20" // Make last list item go over scroll indicator gradient
      style={{
        maxHeight: MAX_LIST_HEIGHT,
      }}
    >
      <div
        // Show a fade gradient at the bottom when the list is cropped and scrollable
        className="sticky left-0 w-full h-0 overflow-visible pointer-events-none z-10"
        style={{ top: MAX_LIST_HEIGHT }}
      >
        <div className="absolute left-0 w-full bottom-0 h-[30px] bg-gradient-to-t from-white to-transparent" />
      </div>
      {children}
    </div>
  );
};
