export function precisionRound(number: number, precision: number) {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
}

export function toRange(number: number, std?: number) {
  const error = std ? std * 2 : number * 0.6;

  const lowEnd = precisionRound(number - error, 0);
  const highEnd = precisionRound(number + error, 0);

  return `${lowEnd} - ${highEnd}`;
}

export function toError(number: number, std?: number) {
  const error = std ? std * 2 : number * 0.6;

  const baseLine = precisionRound(number, 0);
  const delta = precisionRound(error, 0);

  return `${baseLine} ± ${delta}`;
}

// Round to 1 decimal point if only 1 digit
export const autoRound = (n: number) => {
  let precision: number;
  if (Math.abs(n) < 0.1) {
    precision = 3;
  } else if (Math.abs(n) < 1) {
    precision = 2;
  } else if (Math.abs(n) < 10) {
    precision = 1;
  } else {
    precision = 0;
  }
  return precisionRound(n, precision);
};
