import { ComboboxInput } from '@headlessui/react';
import { Input } from 'app/components/Form';
import { uniqueId } from 'lodash';
import { useMemo } from 'react';

type AutocompleteInputPrimitiveProps = {
  onChange: (val: string) => void;
  label?: string;
  placeholder?: string;
  selectedText?: string;
};
export const AutocompleteInputPrimitive = ({
  onChange,
  label,
  placeholder,
}: AutocompleteInputPrimitiveProps) => {
  const htmlId = useMemo(() => uniqueId('input_'), []);

  return (
    <div className="flex flex-row items-center bg-white">
      <ComboboxInput
        isForwarded
        className="w-full"
        placeholder={placeholder}
        label={label}
        refName={htmlId.toString()}
        autoFocus
        as={Input}
        // HeadlessUI doesn't appear to accept different event type
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
