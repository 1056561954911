import Image from 'next/image';

// Circle Flag component
export function CircleFlag(props: { countryCode: string; className?: string }) {
  const { countryCode, ...rest } = props;
  return (
    <Image
      width={12}
      height={12}
      alt="Country Flag"
      src={`/img/flags/${props.countryCode.toLowerCase()}-24px.png`}
      {...rest}
    />
  );
}
